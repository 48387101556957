@import "../../../variables.scss";

.home-page_intro_container {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/cover.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 700px) {
    flex-direction: column;
  }

  &_box {
    img {
      height: 60rem;
      filter: drop-shadow(-3rem -2rem 2rem $color-black);
      @media (max-width: 900px) {
        height: 50rem;
      }
      @media (max-width: 400px) {
        height: 40rem;
      }
      @media (max-height: 600px) {
        height: 30rem;
      }
      animation: mymove 2s ease-in-out infinite;
    }
  }
  &_text {
    white-space: nowrap;
    color: $color-white;
    text-align: center;
    text-shadow: 0.5rem 0.5rem 1rem $color-black;
    h1 {
      font-size: 4.5rem;
      font-weight: 600;
      line-height: 1.6;
      text-transform: uppercase;
      @media (max-width: 900px) {
        font-size: 3.5rem;
      }
    }
    h2 {
      font-size: 3.5rem;
      font-weight: 300;
      letter-spacing: 0.3rem;
      @media (max-width: 900px) {
        font-size: 2.5rem;
      }
    }
  }

  @keyframes mymove {
    0% {
      transform: scale(0.95) rotate(-1deg);
    }
    50% {
      transform: scale(1) rotate(1deg);
    }
    100% {
      transform: scale(0.95) rotate(-1deg);
    }
  }
}
