@import "../../../variables";
@import url("https://fonts.googleapis.com/css?family=Gloria+Hallelujah&display=swap");

.ui_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 10%;
  padding-top: 4rem;
  padding-bottom: 1rem;
  border-bottom: 0.2rem dotted $color-black;
  &_image {
    img {
      width: 7rem;
      height: 7rem;
      @media (max-width: 900px) {
        width: 4rem;
        height: 4rem;
      }
      @media (max-width: 600px) {
        width: 3rem;
        height: 3rem;
      }
      @media (max-width: 400px) {
        width: 2rem;
        height: 2rem;
      }
    }
    &--before {
    }
    &--after {
    }
  }
  &_title {
    h1 {
      font-family: "Gloria Hallelujah", cursive;
      font-size: 5.5rem;
      font-weight: 500;
      letter-spacing: 1rem;
      text-transform: uppercase;
      @media (max-width: 900px) {
        font-size: 3rem;
      }
      @media (max-width: 600px) {
        font-size: 2rem;
        letter-spacing: 0.5rem;
      }
      @media (max-width: 400px) {
        font-size: 1.5rem;
        letter-spacing: 0.5rem;
      }
    }
  }
}
