@import "../../variables";

.feature-page {
  margin: 2rem;
  &_title {
    margin-bottom: 3rem;
  }
  &_blog {
    margin: 0 10%;
    @media (max-width: 700px) {
      margin: 0;
    }
    &_image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3rem 0;
      img {
        max-width: 100%;
      }
      label {
        color: $color-secondary;
        font-size: 1.4rem;
        @media (max-width: 400px) {
          font-size: 1.1rem;
        }
      }
    }

    p {
      font-size: 1.8rem;
      line-height: 2.5rem;
      @media (max-width: 600px) {
        font-size: 1.6rem;
      }
    }
    &_list {
      margin-top: 3rem;
      font-size: 1.6rem;
      li {
        margin-bottom: 1rem;
      }
    }
  }
}
