@import url(https://fonts.googleapis.com/css?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rock+Salt&display=swap);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: "Josefin Sans", sans-serif; }

body {
  box-sizing: border-box;
  background-color: #f1f1e7; }


.home-page_intro {
  height: calc(100vh - 6rem);
  border-bottom: 1rem solid #000; }
  @media (max-width: 400px) {
    .home-page_intro {
      height: calc(100vh - 10rem); } }

.home-page_cards-overview {
  background-color: #c7b299; }

.home-page_intro_container {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/cover.c5b51b41.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  @media (max-width: 700px) {
    .home-page_intro_container {
      flex-direction: column; } }
  .home-page_intro_container_box img {
    height: 60rem;
    -webkit-filter: drop-shadow(-3rem -2rem 2rem #000);
            filter: drop-shadow(-3rem -2rem 2rem #000);
    -webkit-animation: mymove 2s ease-in-out infinite;
            animation: mymove 2s ease-in-out infinite; }
    @media (max-width: 900px) {
      .home-page_intro_container_box img {
        height: 50rem; } }
    @media (max-width: 400px) {
      .home-page_intro_container_box img {
        height: 40rem; } }
    @media (max-height: 600px) {
      .home-page_intro_container_box img {
        height: 30rem; } }
  .home-page_intro_container_text {
    white-space: nowrap;
    color: #fff;
    text-align: center;
    text-shadow: 0.5rem 0.5rem 1rem #000; }
    .home-page_intro_container_text h1 {
      font-size: 4.5rem;
      font-weight: 600;
      line-height: 1.6;
      text-transform: uppercase; }
      @media (max-width: 900px) {
        .home-page_intro_container_text h1 {
          font-size: 3.5rem; } }
    .home-page_intro_container_text h2 {
      font-size: 3.5rem;
      font-weight: 300;
      letter-spacing: 0.3rem; }
      @media (max-width: 900px) {
        .home-page_intro_container_text h2 {
          font-size: 2.5rem; } }

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: scale(0.95) rotate(-1deg);
            transform: scale(0.95) rotate(-1deg); }
  50% {
    -webkit-transform: scale(1) rotate(1deg);
            transform: scale(1) rotate(1deg); }
  100% {
    -webkit-transform: scale(0.95) rotate(-1deg);
            transform: scale(0.95) rotate(-1deg); } }

@keyframes mymove {
  0% {
    -webkit-transform: scale(0.95) rotate(-1deg);
            transform: scale(0.95) rotate(-1deg); }
  50% {
    -webkit-transform: scale(1) rotate(1deg);
            transform: scale(1) rotate(1deg); }
  100% {
    -webkit-transform: scale(0.95) rotate(-1deg);
            transform: scale(0.95) rotate(-1deg); } }

.home-page_cards-overview_container {
  height: 55rem;
  overflow: hidden;
  touch-action: pan-y;
  border-bottom: 7rem solid black; }
  @media (max-width: 900px) {
    .home-page_cards-overview_container {
      height: 50rem; } }
  .home-page_cards-overview_container_track {
    display: flex;
    height: 100%; }
    .home-page_cards-overview_container_track_card {
      flex: 0 0 30rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
      .home-page_cards-overview_container_track_card_card-inner {
        flex-direction: column;
        width: 290px;
        height: 290px;
        border-radius: 5px; }
        .home-page_cards-overview_container_track_card_card-inner img {
          height: 290px;
          border-radius: 1rem;
          box-shadow: 1rem 1rem 2rem #000;
          -o-user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }

.ui_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 10%;
  padding-top: 4rem;
  padding-bottom: 1rem;
  border-bottom: 0.2rem dotted #000; }
  .ui_header_image img {
    width: 7rem;
    height: 7rem; }
    @media (max-width: 900px) {
      .ui_header_image img {
        width: 4rem;
        height: 4rem; } }
    @media (max-width: 600px) {
      .ui_header_image img {
        width: 3rem;
        height: 3rem; } }
    @media (max-width: 400px) {
      .ui_header_image img {
        width: 2rem;
        height: 2rem; } }
  .ui_header_title h1 {
    font-family: "Gloria Hallelujah", cursive;
    font-size: 5.5rem;
    font-weight: 500;
    letter-spacing: 1rem;
    text-transform: uppercase; }
    @media (max-width: 900px) {
      .ui_header_title h1 {
        font-size: 3rem; } }
    @media (max-width: 600px) {
      .ui_header_title h1 {
        font-size: 2rem;
        letter-spacing: 0.5rem; } }
    @media (max-width: 400px) {
      .ui_header_title h1 {
        font-size: 1.5rem;
        letter-spacing: 0.5rem; } }

.ui_title {
  display: flex;
  border-bottom: 1px solid #000;
  padding-bottom: 1rem;
  flex-wrap: wrap; }
  .ui_title_image img {
    width: 10rem; }
    @media (max-width: 600px) {
      .ui_title_image img {
        width: 8rem; } }
  .ui_title_group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: 3rem; }
    .ui_title_group_title h1 {
      font-size: 8rem;
      font-weight: 400;
      letter-spacing: 1rem; }
      @media (max-width: 600px) {
        .ui_title_group_title h1 {
          font-size: 5rem; } }
      @media (max-width: 400px) {
        .ui_title_group_title h1 {
          letter-spacing: 0.5rem; } }
    .ui_title_group_subtitle {
      margin-right: 0.5rem; }
      .ui_title_group_subtitle h2 {
        font-size: 2rem;
        font-weight: 300;
        letter-spacing: 0.5rem; }
        @media (max-width: 600px) {
          .ui_title_group_subtitle h2 {
            font-size: 1.5rem; } }

.buy-page {
  margin: 2rem; }
  .buy-page_title {
    margin-bottom: 3rem; }

.buy-page_product_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.buy-page_product_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
  box-shadow: 1rem 1rem 1rem #000;
  padding: 1rem;
  margin: 2rem;
  border-radius: 2rem; }
  .buy-page_product_item_image img {
    height: 17rem; }
  .buy-page_product_item_container {
    border-top: 0.2rem dashed #000;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .buy-page_product_item_container_title {
      font-family: "Rock Salt", cursive;
      line-height: 5rem; }
    .buy-page_product_item_container_description {
      font-size: 1.5rem;
      color: #959595; }
    .buy-page_product_item_container_price {
      margin-top: 1rem;
      font-family: "Rock Salt", cursive;
      font-size: 1.6rem;
      font-weight: bold;
      color: #b95971; }
    .buy-page_product_item_container_paynow {
      margin-top: 1rem; }

.feature-page {
  margin: 2rem; }
  .feature-page_title {
    margin-bottom: 3rem; }
  .feature-page_blog {
    margin: 0 10%; }
    @media (max-width: 700px) {
      .feature-page_blog {
        margin: 0; } }
    .feature-page_blog_image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3rem 0; }
      .feature-page_blog_image-container img {
        max-width: 100%; }
      .feature-page_blog_image-container label {
        color: #b95971;
        font-size: 1.4rem; }
        @media (max-width: 400px) {
          .feature-page_blog_image-container label {
            font-size: 1.1rem; } }
    .feature-page_blog p {
      font-size: 1.8rem;
      line-height: 2.5rem; }
      @media (max-width: 600px) {
        .feature-page_blog p {
          font-size: 1.6rem; } }
    .feature-page_blog_list {
      margin-top: 3rem;
      font-size: 1.6rem; }
      .feature-page_blog_list li {
        margin-bottom: 1rem; }

.proof-of-authenticity-page {
  margin: 2rem; }
  .proof-of-authenticity-page_title {
    margin-bottom: 3rem; }

.marker_showtext {
  background-color: #b95971;
  padding: 0.5rem;
  border: 1px solid #000;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 1rem #000; }

.pigeon-attribution {
  display: none; }

.pigeon-filters {
  background-color: #f1f1e7; }

.layout_header {
  display: flex;
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  min-height: 6rem; }
  @media (max-width: 400px) {
    .layout_header {
      flex-direction: column;
      min-height: 10rem;
      padding-bottom: 2rem;
      padding-top: 1rem; } }
  .layout_header_logo img {
    margin-bottom: -0.5rem;
    height: 5rem; }
    @media (max-width: 400px) {
      .layout_header_logo img {
        margin-bottom: 0; } }
  .layout_header_nav-items {
    font-size: 2rem; }
    .layout_header_nav-items--active {
      color: #b95971 !important; }
    .layout_header_nav-items ul {
      display: flex;
      list-style: none; }
      .layout_header_nav-items ul li:not(:last-child) {
        margin-right: 2rem;
        padding-right: 2rem;
        border-right: 1px solid #fff; }
      .layout_header_nav-items ul a {
        color: #fff;
        text-decoration: none; }

.layout_footer_banner {
  background-color: #959595;
  overflow: hidden;
  border-top: 1rem solid #000; }
  .layout_footer_banner img {
    max-width: 90%;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    @media (max-width: 500px) {
      .layout_footer_banner img {
        max-width: none;
        max-height: 6rem; } }

.layout_footer_social {
  background-color: #000; }
  .layout_footer_social ul {
    display: flex;
    list-style: none;
    justify-content: center; }
    .layout_footer_social ul li {
      margin: 1rem 0.5rem; }
      .layout_footer_social ul li a img {
        height: 3rem; }

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; }

