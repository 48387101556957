@import "../../../variables";

.ui_title {
  display: flex;
  border-bottom: 1px solid $color-black;
  padding-bottom: 1rem;
  flex-wrap: wrap;

  &_image {
    img {
      width: 10rem;
      @media (max-width: 600px) {
        width: 8rem;
      }
    }
  }
  &_group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: 3rem;
    &_title {
      h1 {
        font-size: 8rem;
        font-weight: 400;
        letter-spacing: 1rem;
        @media (max-width: 600px) {
          font-size: 5rem;
        }
        @media (max-width: 400px) {
          letter-spacing: 0.5rem;
        }
      }
    }
    &_subtitle {
      margin-right: 0.5rem;
      h2 {
        font-size: 2rem;
        font-weight: 300;
        letter-spacing: 0.5rem;
        @media (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
